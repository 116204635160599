export class RetailPerformance {
    public static createMark(name: string) {
        if (process.browser) {
            window.performance.mark(name);
        }
    }

    public static createMeasure(measureName: string, fromMark?: string, toMark?: string) {
        if (process.browser) {
            window.performance.measure(measureName, fromMark, toMark);
        }
    }

    public static doesMarkExist(markName: string) {
        if (process.browser) {
            const entries = window.performance.getEntriesByName(markName);
            return entries.length > 0;
        }

        return false;
    }
}

export default RetailPerformance;
