import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { CarouselTemplate } from './CarouselTemplate';
import { rem } from 'polished';

export const CarouselStyled = styled(CarouselTemplate)`
    position: relative;

    /* more elegant solution */
    #banner {
        &-top_level {
            background-size: 100%;
            height: ${rem(265)};
            background-position: center;
            display: flex;
            align-items: flex-end;
            background-repeat: no-repeat;

            @media (min-width: ${breakpoints.xs}px) {
                height: ${rem(256)};
            }

            @media (min-width: ${breakpoints.sm}px) {
                height: ${rem(240)};
            }

            @media (min-width: ${breakpoints.md}px) {
                height: ${rem(320)};
            }

            @media (min-width: ${breakpoints.lg}px) {
                height: ${rem(400)};
            }
        }
        &-second_level {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .title {
                @media (max-width: ${rem(380)}) {
                    font-size: ${rem(20)}!important;
                    line-height: 1.4;
                    margin-top: 10rem;
                }

                @media (max-width: ${breakpoints.xs}px) {
                    font-size: ${rem(26)};
                    line-height: 1.4;
                    margin-top: 12rem;
                }

                @media (min-width: ${breakpoints.sm}px) {
                    margin-top: 8rem;
                }
            }

            .homebanner_h1 {
                font-size: 20px;

                @media (min-width: ${breakpoints.xs}px) {
                    font-size: 26px;
                }
            }

            .homebanner_wrapper {
                .bar {
                    @media (max-width: 750) {
                        width: 1px;
                    }
                }
            }
        }
    }
    .info-container {
        padding: 1rem 1rem;
        position: absolute;
        bottom: 0;
        color: ${({ theme }) => theme.colors.white};
        z-index: 10;
        text-align: right;
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h1};
        line-height: ${({ theme }) => theme.lineHeights.h1};
        max-width: ${rem(190)};
        color: white;
        margin-top: 10rem;

        @media (min-width: ${breakpoints.xs}px) {
            max-width: ${rem(300)};
        }

        @media (min-width: 745px) {
            max-width: ${rem(180)};
        }
        @media (min-width: ${breakpoints.md}px) {
            max-width: ${rem(370)};
        }
    }
    .next-button {
        right: calc(4% + 1px);
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
        outline: 0;
        transition: all 0.5s;
        z-index: 9;
        background-color: ${({ theme }) => theme.colors.default};
        border: 0;
        min-width: ${rem(30)};
        min-height: ${rem(55)};
        opacity: 1;
        cursor: pointer;

        &:before {
            content: '\\e825';
            font-size: ${rem(20)};
            color: #fff;
            display: block;
            font-family: revicons-override !important;
            text-align: center;
            z-index: 2;
            position: relative;
        }

        @media (min-width: ${breakpoints.md}px) {
            min-width: ${rem(55)};
        }
    }

    .prev-button {
        left: calc(4% - 1px);
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
        outline: 0;
        transition: all 0.5s;
        z-index: 9;
        background-color: ${({ theme }) => theme.colors.default};
        border: 0;
        min-width: ${rem(30)};
        min-height: ${rem(55)};
        opacity: 1;
        cursor: pointer;

        &:before {
            content: '\\e824';
            font-size: ${rem(20)};
            color: #fff;
            display: block;
            font-family: revicons-override !important;
            text-align: center;
            z-index: 2;
            position: relative;
        }

        @media (min-width: ${breakpoints.md}px) {
            min-width: ${rem(55)};
        }
    }

    .innerHtml {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 4.5rem;
    }

    .innerPosition {
        position: absolute;
        bottom: 0;
    }

    .htmltext {
        display: flex;
        align-items: flex-end;
        color: white;

        p {
            margin: 0;
        }

        &-NoUrl {
            flex-direction: column;
        }
    }

    .background {
        display: flex;
        justify-content: center;
        min-height: 30rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .styled-button {
        padding: 1.1rem 4rem;
        background: ${({ theme }) => theme.colors.primary};
        text-align: center;
        cursor: pointer;
        margin-left: auto;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        position: absolute;
        bottom: ${rem(40)};
        right: ${rem(40)};

        :hover {
            background: ${({ theme }) => theme.colors.primaryHover};
        }
    }

    .note {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        line-height: ${({ theme }) => theme.lineHeights.h5};
    }

    .button {
        width: 17rem;
    }
`;
