import { useSelector } from 'react-redux';
import UIDuck from '../redux/commonDucks/ui.duck';
import useCustomRouter from './useCustomRouter';
import { useFAQCategoryFromHash } from './useFAQCategoryFromHash';
import { faqsTemplateId } from '@components/FAQs';

export const useScrollToPositionOnMount = () => {
    let scrollPosition = useSelector(state => UIDuck.getScrollPosition(state));
    const router = useCustomRouter();
    const { showFAQ } = useFAQCategoryFromHash();

    const handleScrollTo = () => {
        if (!router?.asPath?.includes('#')) return;
        if (typeof window === 'undefined') return;

        if (showFAQ) scrollPosition = faqsTemplateId;

        const element =
            typeof scrollPosition === 'string'
                ? document.getElementById(scrollPosition) || undefined
                : scrollPosition?.current || undefined;

        console.log('scroll__', element);

        if (scrollPosition && element) {
            element.scrollIntoView();
        } else if (!element) {
            window.scrollTo(0, 0);
        }
    };

    return { handleScrollTo };
};

export default useScrollToPositionOnMount;
