import styled from 'styled-components';
import { JourneySwitcherStyled } from '../JourneySwitcherStyled';
import { rem } from 'polished';

export const JourneySwitcherStyledDS = styled(JourneySwitcherStyled)`
    .option {
        text-transform: unset;
    }

    .web-title {
        font-weight: 700;
    }

    .active {
        color: ${({ theme }) => theme.colors.black};

        .mobile-title,
        .web-title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .not-active {
        .mobile-title,
        .web-title {
            &:hover,
            &:focus-visible {
                color: ${({ theme }) => theme.colors.primary};
                text-decoration: underline;
            }
        }
    }

    .back-container-not-active {
        margin-top: 0;
    }

    .option {
        padding: ${rem('17px')} 0;
        &.not-active {
            padding: ${rem('17px')} 0;
        }
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .back-container-active {
        border-bottom: ${rem(4)} solid ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.white};
        border-radius: 0;
    }

    .back-container-not-active {
        border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.primary};
    }

    .not-active {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
    }
`;
