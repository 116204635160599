import React from 'react';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import { OfferListItemStyledAC } from './AC/OfferListItemStyled.AC';
import { OfferListItemStyledAP } from './AP/OfferListItemStyled.AP';
import { OfferListItemStyledDS } from './DS/OfferLIstItemStyled.DS';
import { OfferListItemStyledOV } from './OV/OfferListItemStyled.OV';
import { OfferListItemStyledOV as OfferListItemStyledOV_GB } from './OV/GB/OfferListItemStyled.OV';
import { OfferListItemStyled } from './OfferListItemStyled';

import { IOfferItemWithIndex } from '../../interfaces/IOfferItem';
import { SC } from '../../styles/theme';

export interface OfferListItemProps extends SC {
    offer: IOfferItemWithIndex;
    disabled?: boolean;
    onImageLoad?: (event: React.SyntheticEvent<HTMLImageElement>) => void;
    isStockJourney?: boolean;
    index: number;
    isNeedHandItem?: boolean;
}

const OfferListItem = (props: OfferListItemProps) => {
    if (isBrandOV) return isMarketGB ? <OfferListItemStyledOV_GB {...props} /> : <OfferListItemStyledOV {...props} />;
    if (isBrandAC) return <OfferListItemStyledAC {...props} />;
    if (isBrandAP) return <OfferListItemStyledAP {...props} />;
    if (isBrandDS) return <OfferListItemStyledDS {...props} />;
    return <OfferListItemStyled {...props} />;
};

export default OfferListItem;
