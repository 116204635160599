import { BRAND } from '../../constants/main';
import { CarouselStyledAC } from './AC/CarouselStyled.AC';
import { CarouselStyledOV } from './OV/CarouselStyled.OV';
import { CarouselStyledAP } from './AP/CarouselStyled.AP';
import { CarouselStyledDS } from './DS/CarouselStyled.DS';
import { CarouselStyled } from './CarouselStyled';
import { SC } from '../../styles/theme';
import { IBannerItem } from '../../redux/content/content.duck.interface';

export type CarouselProps = SC & { banners: IBannerItem[]; autoPlay?: boolean };

const Carousel = (props: CarouselProps) => {
    if (BRAND === 'AC') return <CarouselStyledAC {...props} />;
    if (BRAND === 'OV') return <CarouselStyledOV {...props} />;
    if (BRAND === 'AP') return <CarouselStyledAP {...props} />;
    if (BRAND === 'DS') return <CarouselStyledDS {...props} />;
    return <CarouselStyled {...props} />;
};

export default Carousel;
