import { useEffect, useState } from 'react';
import { useSsr } from '@hooks/useSsr';

/**
 * @param mediaQuery - format must be in form of: '(min-width: css-measure porperty)'
 * @example isDesktop = useMediaQuery(("min-width:1024px"));
 *
 * this hook must not be on serverside level! if so use condition that window is defined
 */

export const useMediaQuery = (mediaQuery: string): boolean => {
    const { isBrowser, isServer } = useSsr();
    const [isMatchingMediaQuery, setIsMatchingMediaQuery] = useState(
        isBrowser && window?.matchMedia(mediaQuery).matches
    );

    useEffect(() => {
        if (isBrowser) {
            const media = window.matchMedia(mediaQuery);

            if (media.matches !== isMatchingMediaQuery) {
                setIsMatchingMediaQuery(media.matches);
            }
            const listener = () => setIsMatchingMediaQuery(media.matches);

            media.addListener(listener);

            return () => media.removeListener(listener);
        }
    }, [mediaQuery, isBrowser]);

    if (isServer) {
        return false;
    }

    return isMatchingMediaQuery;
};
