import styled from 'styled-components';
import { css, breakpoints } from '../../styles/theme';
import OfferListItemTemplate from './OfferListItemTemplate';
import { rem } from 'polished';

export const OfferListItemStyled = styled(OfferListItemTemplate)`
    border: 1px solid ${({ theme }) => theme.colors.grey8};
    background: ${({ theme }) => theme.colors.white};
    padding: ${rem('15px')};
    width: 100%;
    min-width: ${rem(260)};
    position: relative;

    &.isNeedHandItem {
        display: flex;
        padding: unset;
        overflow: hidden;
    }

    @media (max-width: ${breakpoints.sm}px) {
        grid-template-rows: unset !important;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `};
    &.with-sticker {
        padding-top: ${rem(50)};

        .carMarketingSticker {
            position: absolute;
            right: ${rem(15)};
            top: ${rem(15)};
            padding-left: ${rem(15)};
        }

        .carTitleWrap {
            margin-top: ${rem(30)};
            z-index: 9999;
        }
    }
    .carTitleWrap {
        display: flex;
        span {
            text-transform: uppercase;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
        &--link,
        a {
            color: ${({ theme }) => theme.colors.black};
            text-decoration: none;
        }
        &--totalCount {
            border: 1px solid ${({ theme }) => theme.colors.grey4};
            border-radius: ${rem('16px')};
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            padding: ${rem('6px')} ${rem('15px')};
            text-transform: uppercase;
            display: inline;
            white-space: nowrap;
            height: ${rem('30px')};
            margin-left: auto;
        }
    }
    .carImageWrap {
        text-align: center;

        img {
            width: 100%;
        }
    }
    .descriptionWrap {
        color: ${({ theme }) => theme.colors.grey13};
        font-size: ${rem('11px')};
        line-height: ${rem('14px')};
        &-payment {
            padding-bottom: ${rem('10px')};
            &-link {
                color: ${({ theme }) => theme.colors.primary};
                cursor: pointer;
                background: none;
                padding: 0 0 0 ${rem('5px')};
                border: none;
            }
        }
    }
`;
