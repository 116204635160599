import React, { FC, memo } from 'react';
import OfferListItem from '../../OfferListItem';
import { IOfferItemWithIndex } from '../../../interfaces/IOfferItem';
import { HandledComponentError } from '@components/ErrorHandling';
import { hasPromoCodeOffersList } from '@utils/Promo.utils';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { ContentDuck } from '../../../redux/content/content.duck';
import { useSelector } from 'react-redux';
import { Redux } from '../../../redux/redux.interface';
import { NEED_HAND_SETTING_KEY } from '../../../constants/main';

interface IOffersList {
    list: IOfferItemWithIndex[];
    isDisabled: boolean;
    onPodLoaded: () => void;
}

export const OffersList: FC<IOffersList> = memo(({ list, isDisabled, onPodLoaded }) => {
    const { isStockJourney } = useCarStockJourneyCheck();

    const settings = useSelector((state: Redux) => ContentDuck.getSettings(state));

    const needHandBlockOrder = Number(settings.find((setting) => setting.key === NEED_HAND_SETTING_KEY)?.value || 0);

    try {
        return (
            <>
                {list
                    .map((item, index) => ({
                        ...item,
                        hasPromoCode: hasPromoCodeOffersList(list, index),
                    }))
                    .map((offer, k) => (
                        <>
                            {needHandBlockOrder > 0 && k + 1 === needHandBlockOrder && (
                                <OfferListItem key={`needHand-${k + 1}`} index={k} offer={offer} isNeedHandItem />
                            )}
                            <OfferListItem
                                key={`${k + 1}`}
                                index={k}
                                offer={offer}
                                onImageLoad={onPodLoaded}
                                disabled={isDisabled || offer.isDisabled}
                                isStockJourney={isStockJourney}
                            />
                            {needHandBlockOrder > list?.length && k + 1 === list?.length && (
                                <OfferListItem key={`needHand-${k + 1}`} index={k} offer={offer} isNeedHandItem />
                            )}
                        </>
                    ))}
            </>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});
OffersList.displayName = 'OffersList';
