import React, { FC, memo } from 'react';
import Main from '@components/Layout/Main';
import FAQs from '@components/FAQs';
import CustomError from '@components/CustomError';
import { useTranslations } from '@hooks/useTranslations';
import routes from '../../constants/routes';
import { NeedHelpSection } from '@components/NeedHelpSection';

interface IProps {
    statusCode?: number;
}
export const SSRError: FC<IProps> = memo(({ statusCode }) => {
    const { t } = useTranslations();

    const status = statusCode || 500;

    return (
        <Main title={status}>
            <CustomError
                errorType={String(status)}
                returnLink={routes.ROOT}
                returnLinkLabel={t('errorPage.returnLink.label')}
                titleDescription={t('errorPage.notFound.description')}
            />
            <NeedHelpSection />
            <FAQs />
        </Main>
    );
});
SSRError.displayName = 'SSRError';
