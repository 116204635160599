import styled from 'styled-components';
import { JourneySwitcherStyled } from '../JourneySwitcherStyled';
import { rem } from 'polished';

export const JourneySwitcherStyledAC = styled(JourneySwitcherStyled)`
    .active {
        border-top-right-radius: ${rem('12px')};
        border-top-left-radius: ${rem('12px')};
        color: ${({ theme }) => theme.colors.grey5};
        border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    }

    .back-container-not-active {
        background: ${({ theme }) => theme.colors.grey15};
        border-bottom: 2px solid ${({ theme }) => theme.colors.grey3};
        border-top-right-radius: ${rem('12px')};
        border-top-left-radius: ${rem('12px')};
    }
    
    .back-container-active {
        background: ${({ theme }) => theme.colors.white};
        border-top-right-radius: ${rem('12px')};
        border-top-left-radius: ${rem('12px')};
    }

    .option {
        padding: ${rem('24px')} 0;
    }

    .not-active {
        background: #f5f5f5;
        border-top-right-radius: ${rem('12px')};
        border-top-left-radius: ${rem('12px')};
        .web-title,
        .mobile-title {
            color: ${({ theme }) => theme.colors.grey1};
        }
        
        &-right {
            border-bottom-left-radius: unset;
        }
        &-left {
            border-bottom-right-radius: unset;
        }
    }

    .web-title {
        font-size: ${({ theme }) => theme.fontSizes.h3};
    }
    
    .web-title,
    .mobile-title {
        color: ${({ theme }) => theme.colors.red};
        text-transform: none;
        padding: 0;
    }
`;
