import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { JourneySwitcherTemplate } from './JourneySwitcherTemplate';
import { rem } from 'polished';

export const JourneySwitcherStyled = styled(JourneySwitcherTemplate)`

    ${(prop) => (prop.withoutBanners 
        ? `
            margin: 0 auto;
            display: flex;
        ` 
        : `
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
        `
    )};

    .web-title,
    .mobile-title {
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        line-height: ${({ theme }) => theme.lineHeights.textMedium};
        padding: 0 ${rem('10px')};
    }

    @media (min-width: ${breakpoints.xs}px) {
        max-width: 94rem;
        ${(prop) => (prop.isStock ? '' : `padding: 0 1rem;`)};
        left: 50%;
        ${(prop) => (prop.withoutBanners ? '' : `transform: translate(-50%, 0);`)};
    }

    .back-container {
        width: 50%;
        border-top-right-radius: ${rem('5px')};
    }

    .back-container-not-active {
        margin-top: ${rem('9px')};
        background: ${({ theme }) => theme.colors.white};
    }

    .web-title {
        display: none;

        @media (min-width: ${breakpoints.md}px) {
            display: block;
        }
    }

    .mobile-title {
        display: block;
        font-size: ${rem('14px')};

        @media (min-width: ${breakpoints.md}px) {
            display: none;
        }
    }

    .option {
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: ${rem('15px')} 0;
        text-transform: uppercase;
        cursor: pointer;
    }

    .active {
        background: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        padding-top: ${rem('24px')};
        color: ${({ theme }) => theme.colors.primary};

        @media (max-width: ${breakpoints.sm}px) {
            border-radius: ${rem('5px')} ${rem('5px')} 0 0;
        }
    }

    .not-active {
        color: ${({ theme }) => theme.colors.white};

        button {
            &:focus-visible {
                outline-color: ${({ theme }) => theme.colors.white};
            }
        }

        .web-title,
        .mobile-title {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }
    }

    .not-active-left {
        border-bottom-right-radius: ${rem('10px')};
    }

    .not-active-right {
        border-bottom-left-radius: ${rem('10px')};
    }

    .square {
        position: absolute;
        background: ${({ theme }) => theme.colors.white};
        width: 10%;
        height: ${rem('30px')};
        bottom: 0;
        left: 45%;
        z-index: -1000;
    }
`;
