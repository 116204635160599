import { useEffect } from 'react';
import UIDuck from '../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';

export const useLoadingReset = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResetLoading = () => {
            dispatch(UIDuck.setLoading(-100));
        };

        window.addEventListener('popstate', handleResetLoading, false);

        return () => window.removeEventListener('popstate', handleResetLoading);
    }, [dispatch]);
};

export default useLoadingReset;
