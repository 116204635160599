import { CarouselProps } from '.';
import React, { FC, useEffect, useState, useCallback } from 'react';
import Button from 'src/components/Button';
import { Col, Grid, Row } from 'src/components/Grid';
import { useMediaQuery } from '@hooks/useMediaquery';
import { useTheme } from '@hooks/useTheme';
import useTranslations from '@hooks/useTranslations';
import JourneySwitcher from 'src/components/JourneySwitcher';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { CarJourneyType } from 'src/services';
import { useInterval } from 'react-use';
import { IBannerItem } from 'src/redux/content/content.duck.interface';

export const CarouselTemplate: FC<CarouselProps> = ({ className, banners, autoPlay }) => {
    const [bannerVariant, setBannerVariant] = useState<keyof IBannerItem>('largeImage');
    const [index, setIndex] = useState(0);
    const [bannerArrowClicked, setBannerArrowClicked] = useState(false);

    const theme = useTheme();
    const { t } = useTranslations();
    const hasMultipleBanners = banners?.length > 1;

    const isStockJourneyAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS,
        CarJourneyType.STOCK
    );

    const xs = useMediaQuery(`(min-width: ${theme.flexboxgrid.breakpoints.xs}em)`);
    const sm = useMediaQuery(`(min-width: ${theme.flexboxgrid.breakpoints.sm}em)`);
    const md = useMediaQuery(`(min-width: ${theme.flexboxgrid.breakpoints.md}em)`);
    const lg = useMediaQuery(`(min-width: ${theme.flexboxgrid.breakpoints.lg}em)`);

    const bannerVariantCalc = () => {
        switch (true) {
            case !xs && !sm: {
                return 'extraSmallImage';
            }

            case xs && !md: {
                return 'smallImage';
            }

            case md && !lg: {
                return 'mediumImage';
            }

            default: {
                return 'largeImage';
            }
        }
    };

    useEffect(() => {
        setBannerVariant(bannerVariantCalc);
    }, [xs, sm, md, lg]);

    const bannerToRight = useCallback(() => {
        if (index === banners.length - 1) setIndex(0);
        else setIndex(index + 1);
    }, [banners, setIndex, index]);

    const bannerToLeft = useCallback(() => {
        if (index === 0) setIndex(banners.length - 1);
        else setIndex(index - 1);
    }, [banners, setIndex, index]);

    const bannerRightArrowClicked = useCallback(() => {
        setBannerArrowClicked(true);
        bannerToRight();
    }, [bannerToRight]);

    const bannerLeftArrowClicked = useCallback(() => {
        setBannerArrowClicked(true);
        bannerToLeft();
    }, [bannerToLeft]);

    useInterval(bannerToRight, autoPlay && hasMultipleBanners && !bannerArrowClicked ? 6000 : null);

    const banner = banners[index];

    const linkHandler = useCallback(() => {
        banner.redirectUrl && (window.location.href = banner.redirectUrl);
    }, [banner]);

    const bannerImage = banner?.[bannerVariant];
    const showTitle = !bannerImage;

    const RenderBanner = () => {
        if (banner) {
            return (
                <div
                    id="banner-top_level"
                    data-testid="banner-top_level"
                    key={banner.id}
                    className="image-placement"
                    style={{ backgroundImage: `url('${bannerImage}')`, position: 'relative' }}
                >
                    {hasMultipleBanners && (
                        <>
                            <button onClick={bannerLeftArrowClicked} className="prev-button" id="TESTING_BANNER_PREV" />
                            <button
                                onClick={bannerRightArrowClicked}
                                className="next-button"
                                id="TESTING_BANNER_NEXT"
                            />
                        </>
                    )}
                    <Grid style={{ height: '100%', width: '100%' }}>
                        <div id="banner-second_level">
                            {banner.redirectUrl !== '' ? (
                                <>
                                    {showTitle && banner.title && (
                                        <Row>
                                            <Col xs={12}>
                                                <h2 className="title">{banner.title}</h2>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="innerHtml">
                                        <Col
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                className="htmltext"
                                                dangerouslySetInnerHTML={{
                                                    __html: banner.htmlText,
                                                }}
                                            />
                                            <div className="button">
                                                <Button primary id="TESTING_BANNER_LINK" onClick={linkHandler}>
                                                    {banner.ctaLabel || t('banner.button.learnMore')}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Row className="innerHtml innerPosition">
                                    <Col style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <div
                                            className="htmltext htmltext-NoUrl"
                                            dangerouslySetInnerHTML={{ __html: banner.htmlText }}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {isStockJourneyAllowed && <JourneySwitcher isStock />}
                        </div>
                    </Grid>
                </div>
            );
        }

        if (!banner && isStockJourneyAllowed) {
            return <JourneySwitcher withoutBanners />;
        }

        return null;
    };

    return (
        <div className={className}>
            <RenderBanner />
        </div>
    );
};
