import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { OfferListItemStyled } from '../OfferListItemStyled';
import { rem } from 'polished';

export const OfferListItemStyledAC = styled(OfferListItemStyled)`
    display: grid;
    border: none;
    grid-template-rows: ${rem(60)} ${rem(240)} 1fr ${rem(64)} ${({ isStockJourney }) => (isStockJourney ? 0 : rem(80))};
    border-radius: ${rem(12)};

    .carTitleWrap--link {
        font-size: ${({ theme }) => theme.fontSizes.textBig};
        line-height: ${({ theme }) => theme.lineHeights.textBig};
    }

    .carTitleWrap--totalCount {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        background: #f5f5f5;
        color: ${({ theme }) => theme.colors.grey5};
        border: none;
    }

    .currencyLabel__suffix--TTC {
        @media (max-width: ${breakpoints.xs}px) {
            font-size: ${rem(12)};
        }
    }

    .descriptionWrap {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        color: ${({ theme }) => theme.colors.grey1};

        &-payment-link {
            color: ${({ theme }) => theme.colors.red};
        }
    }
`;
