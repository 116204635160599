import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CarouselStyled } from '../CarouselStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const CarouselStyledAC = styled(CarouselStyled)`
    .button {
       display: none;
    }
    @media (min-width: ${breakpoints.sm}px) {
        .button {
            display: block;
            position: relative;
            bottom: 0;
            width: 17rem;
        }
    }

    #banner {
        &-top_level {
            background-size: 100% 100%;
            height: calc(100vw*0.71);
            background-position-x: center;
            display: flex;
            align-items: flex-end;
            background-repeat: no-repeat;

            ${media.xs`
                height: calc(100vw*0.3116883);
            `}

            ${media.sm`
                height: calc(100vw*0.3112676056);
            `}

            ${media.md`
                height: calc(100vw*0.3118279569);
            `}

            ${media.lg`
                height: calc(100vw*0.277494537);
            `}
        }
    }
    
    .prev-button,
    .next-button {
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.red};
        border-radius: ${rem(12)};
        
        &:before {
            color: ${({ theme }) => theme.colors.red};
        }
        
        &:hover {
            border-color: ${({ theme }) => theme.colors.grey5};
            
            &:before {
                color: ${({ theme }) => theme.colors.grey5};
            }
        }
    }

    .image-placement {
        background-position-y: inherit !important;
        background-position-x: inherit !important;
        background-size: cover;
    }
`;
