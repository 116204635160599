import styled from 'styled-components';
import { JourneySwitcherStyled } from '../JourneySwitcherStyled';
import { rem } from 'polished';

export const JourneySwitcherStyledOV = styled(JourneySwitcherStyled)`
    .active {
        border-top-right-radius: ${rem('5px')};
        border-top-left-radius: ${rem('5px')};
    }
    .active,
    .not-active {
        color: ${({ theme }) => theme.colors.black};
    }

    .not-active {
        background-color: ${({ theme }) => theme.colors.grey20};
    }
    .active {
        background-color: ${({ theme }) => theme.colors.pureWhite};
    }

    .not-active-left {
        border-top-left-radius: ${rem('5px')};
    }

    .not-active-right {
        border-top-right-radius: ${rem('5px')};
    }

    .back-container-active {
        background-color: ${({ theme }) => theme.colors.pureWhite};
    }

    .web-title,
    .mobile-title {
        font-size: ${({ theme }) => theme.fontSizes.textBig};
        color: ${({ theme }) => theme.colors.blue7};
    }
`;
