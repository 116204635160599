import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { OfferListItemStyled } from '../OfferListItemStyled';

export const OfferListItemStyledDS = styled(OfferListItemStyled)`
    display: grid;
    border-radius: ${rem(16)};
    border-color: ${({ theme }) => theme.colors.grey16};
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    grid-template-rows: ${rem(60)} ${rem(250)} 1fr ${rem(64)} ${({ isStockJourney }) => (isStockJourney ? 0 : rem(80))};

    &.isNeedHandItem {
        display: flex;
    }

    .currencyLabel__suffix--TTC {
        @media (max-width: ${breakpoints.xs}px) {
            font-size: ${rem(12)};
        }
    }

    &.with-sticker {
        .carMarketingSticker {
            position: absolute;
            right: ${rem(0)};
            top: ${rem(15)};
        }
    }

    .carTitleWrap {
        &--totalCount {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            margin-right: -${rem(16)};
            right: 0;
            border-color: ${({ theme }) => theme.colors.grey16};
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &--link {
            font-size: ${rem(26)};
            text-transform: uppercase;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .currencyLabel__suffix--OTR {
        font-size: ${rem(14)};
    }

    .priceWrap--monthlyPrice .currencyLabel__suffix--PM {
        font-size: ${rem(16)};
        font-weight: ${({ theme }) => theme.fontWeights.textLight};
    }

    .descriptionWrap {
        font-size: ${rem(12)};
        color: ${({ theme }) => theme.colors.grey29};
        margin-top: ${rem(5)};
    }
    .descriptionWrap-gb {
        color: ${({ theme }) => theme.colors.grey24};
    }

    .descriptionWrap-payment-link {
        color: #000000;
        text-decoration: underline;

        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    .descriptionWrap-payment-gb {
        color: ${({ theme }) => theme.colors.grey24};
    }

    .descriptionWrap-payment-sol {
        font-size: ${rem(12)};
    }

    .priceWrap--label {
        font-size: ${rem(10)};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        color: ${({ theme }) => theme.colors.black1};
    }

    .priceWrap--priceLabel {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    .priceWrap--monthlyPrice {
        color: ${({ theme }) => theme.colors.black1};
    }

    .buttonWrapper {
        flex-basis: 0;
        flex-grow: 0;
        margin: 0 0 0 ${rem('10px')};

        a {
            height: ${rem('48px')};
            font-size: ${rem('16px')};

            @media (max-width: ${breakpoints.sm}px) {
                margin-top: ${rem('10px')};
            }
        }
    }

    .priceWrap {
        padding: ${rem('5px')};

        @media (min-width: ${breakpoints.xs}px) {
            padding: ${rem('20px')};
        }
    }
`;
