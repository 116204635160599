import React, { memo } from 'react';
import { BRAND, BRAND_AP, BRAND_AC, BRAND_DS, BRAND_OV, isMarketGB } from '../../constants/main';
import { JourneySwitcherStyled } from './JourneySwitcherStyled';
import { JourneySwitcherStyledAC } from './AC/JourneySwitcherStyled.AC';
import { JourneySwitcherStyledAP } from './AP/JourneySwitcherStyled.AP';
import { JourneySwitcherStyledDS } from './DS/JourneySwitcherStyled.DS';
import { JourneySwitcherStyledOV } from './OV/JourneySwitcherStyled.OV';
import { JourneySwitcherStyledOVGB } from './OV/GB/JourneySwitcherStyled.OV';
import { SC } from '../../styles/theme';

const componentMap = {
    [BRAND_AC]: JourneySwitcherStyledAC,
    [BRAND_AP]: JourneySwitcherStyledAP,
    [BRAND_DS]: JourneySwitcherStyledDS,
    [BRAND_OV]: isMarketGB ? JourneySwitcherStyledOVGB : JourneySwitcherStyledOV,
};

export interface SwitcherProps extends SC {
    withoutBanners?: boolean;
    isStock?: boolean;
}

const JourneySwitcher = memo((props: SwitcherProps) => {
    const JourneySwitcherComponent = BRAND ? componentMap[BRAND] : JourneySwitcherStyled;
    return <JourneySwitcherComponent {...props} />;
});
JourneySwitcher.displayName = 'JourneySwitcher';

export default JourneySwitcher;
