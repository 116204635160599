import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { CarouselStyled } from '../CarouselStyled';
import media from '../../../styles/media';

export const CarouselStyledDS = styled(CarouselStyled)`
    .button {
       display: none;
    }
    .prev-button,
    .next-button {
        &:hover,
        &:focus-visible {
            background: ${({ theme }) => theme.colors.grey4};
            border: 1px solid ${({ theme }) => theme.colors.primary};

            &:before {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
    @media (min-width: ${breakpoints.sm}px) {
        .button {
            display: block;
            position: relative;
            bottom: 0;
            width: 17rem;
            margin-top: 1.5rem;

            button {
                background: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    #banner {
        &-top_level {
            background-size: 100% 100%;
            height: calc(100vw*0.8);
            background-position-x: center;
            display: flex;
            align-items: flex-end;
            background-repeat: no-repeat;

            ${media.xs`
                height: calc(100vw*0.3125);
            `}

            ${media.sm`
                height: calc(100vw*0.3112676056);
            `}

            ${media.md`
                height: calc(100vw*0.3118279569);
            `}

            ${media.lg`
                height: calc(100vw*0.277494537);
            `}
        }
    }

    .image-placement {
        background-position-y: inherit !important;
        background-size: cover;
        background-position-x: inherit !important;
    }
`;
