import React, { FC } from 'react';
import { CarJourneyType } from 'src/services/carList/carList.types';
import { buildParameterizedPathname } from '@utils/url.utils';
import routes from 'src/constants/routes';
import useTranslations from '@hooks/useTranslations';
import useCustomRouter from '@hooks/useCustomRouter';
import { useDispatch, useSelector } from 'react-redux';
import GlobalDuck from 'src/redux/global/global.duck';
import { Redux } from 'src/redux/redux.interface';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import { SwitcherProps } from '@components/JourneySwitcher/index';
import FilterDuck from '../../redux/filters/filter.duck';
import { getStockFilters } from '../../services/filters/filterHelpers';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { useRouter } from "next/router";
import {useGlobalDuck} from "@hooks/useGlobalDuck";

export const JourneySwitcherTemplate: FC<SwitcherProps> = React.memo(({ className, withoutBanners = false }) => {
    const router = useRouter();
    const { t } = useTranslations();
    const dispatch = useDispatch();

    const { carJourney } = useGlobalDuck();
    const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));

    const handleSwitchClick = (journey: CarJourneyType) => {
        dispatch(GlobalDuck.setCarJourney(journey));
        dispatch(FilterDuck.resetFilters(filters));
        dispatch(FilterDuck.resetGeoLocationProperties());
        dispatch(DealerDuck.setDealer(null));
        window.scrollTo(0, 0);
    };

    const getButtonClassNameByJourneyType = (journey: CarJourneyType) => {
        if (carJourney === journey || router.pathname.includes(journey)) {
            return 'active';
        }

        return `not-active not-active-${journey === CarJourneyType.CONFIGURABLE ? 'left' : 'right'}`;
    };

    return (
        <div className={`${className} ${withoutBanners ? 'withoutBanners' : ''}`}>
            <div
                className={`back-container ${
                    ![carJourney, router.pathname?.replace('/', '')].includes(CarJourneyType.CONFIGURABLE)
                        ? 'back-container-not-active'
                        : 'back-container-active'
                }`}
            >
                <AccessibleButton
                    style={{ width: '100%' }}
                    tabIndex={0}
                    onClick={() => handleSwitchClick(CarJourneyType.CONFIGURABLE)}
                >
                    <div className={`option ${getButtonClassNameByJourneyType(CarJourneyType.CONFIGURABLE)}`}>
                        <h2 className="web-title">{t('journeySwitcher.configurableLabel')}</h2>
                        <h2 className="mobile-title">{t('journeySwitcher.configurableMobileLabel')}</h2>
                    </div>
                </AccessibleButton>
            </div>
            <div
                className={`back-container ${
                    ![carJourney, router.pathname?.replace('/', '')].includes(CarJourneyType.STOCK)
                        ? 'back-container-not-active'
                        : 'back-container-active'
                }`}
            >
                <AccessibleButton
                    style={{ width: '100%' }}
                    tabIndex={0}
                    onClick={() => handleSwitchClick(CarJourneyType.STOCK)}
                >
                    <div className={`option ${getButtonClassNameByJourneyType(CarJourneyType.STOCK)}`}>
                        <h2 className="web-title">{t('journeySwitcher.stockLabel')}</h2>
                        <h2 className="mobile-title">{t('journeySwitcher.stockMobileLabel')}</h2>
                    </div>
                </AccessibleButton>
            </div>
        </div>
    );
});

JourneySwitcherTemplate.displayName = 'JourneySwitcherTemplate';
