import styled from 'styled-components';
import { JourneySwitcherStyled } from '../JourneySwitcherStyled';
import { rem } from 'polished';

export const JourneySwitcherStyledAP = styled(JourneySwitcherStyled)`
    .active {
        border-top-right-radius: ${rem('5px')};
        border-top-left-radius: ${rem('5px')};
    }
    
    .back-container-active {
        background: ${({ theme }) => theme.colors.white};
    }
    
    .back-container-not-active {
        background: ${({ theme }) => theme.colors.primary};
    }
`;
