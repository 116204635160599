import styled from 'styled-components';
import { OfferListItemStyled } from '../OfferListItemStyled';
import { rem } from 'polished';

export const OfferListItemStyledOV = styled(OfferListItemStyled)`
    display: grid;
    grid-template-rows: ${rem(60)} ${rem(200)} 1fr ${rem(64)} ${({ isStockJourney }) => (isStockJourney ? 0 : rem(80))};
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-color: ${({ theme }) => theme.colors.grey20};
    padding-bottom: ${({ isStockJourney }) => (isStockJourney ? 0 : rem('15px'))};

    .carTitleWrap {
        h2.carTitleWrap--link {
            color: ${({ theme }) => theme.colors.black};
            font-size: ${rem('21px')};
            line-height: ${rem('23px')};
            text-transform: uppercase;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: normal;
        }
    }

    .descriptionWrap {
        color: ${({ theme }) => theme.colors.grey22};
        font-size: ${rem('12px')};
        line-height: ${rem('14px')};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-weight: normal;

        &-payment {
            padding-bottom: ${rem('10px')};

            &-link {
                color: ${({ theme }) => theme.colors.grey1};
                cursor: pointer;
                padding-left: ${rem('5px')};
                text-decoration: underline;
            }
        }
    }
`;
