import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { OfferListItemStyled } from '../../OfferListItemStyled';
import { rem } from 'polished';

export const OfferListItemStyledOV = styled(OfferListItemStyled)`
    display: grid;
    grid-template-rows: ${rem(60)} ${rem(200)} 1fr ${rem(64)} ${rem(80)};

    .carTitleWrap--link {
        color: ${({ theme }) => theme.colors.black};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-size: ${rem('21px')};
    }

    .carTitleWrap {
        h2 {
            font-size: ${rem('21px')};
            text-transform: uppercase;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
        }
    }

    .carTitleWrap--totalCount {
        font-weight: 300;
        line-height: ${rem('16px')};
    }

    .descriptionWrap {
        color: ${({ theme }) => theme.colors.grey1};
        font-size: ${rem('12px')};
        line-height: ${rem('14px')};
        font-family: ${({ theme }) => theme.fonts.fontSecondary};

        &-payment {
            padding-bottom: ${rem('10px')};

            &-link {
                color: ${({ theme }) => theme.colors.grey1};
                cursor: pointer;
                padding-left: ${rem('5px')};
                text-decoration: underline;
            }
        }
    }
`;
