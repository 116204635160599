import { config } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { breakpoints } from '../../styles/AC/theme';
import { BRAND_TYPE } from '../../constants/main';

export default styled.div<{ brand?: BRAND_TYPE }>`
    ${({ theme }) => config(theme.flexboxgrid).media.lg`
        width: 100%;
    `};

    @media (max-width: ${breakpoints.xs}px) {
        margin-top: 0;
    }
`;
