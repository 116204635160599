import { breakpoints } from '../../../styles/theme';
import styled from 'styled-components';
import { CarouselStyled } from '../CarouselStyled';
import media from '../../../styles/media';

export const CarouselStyledAP = styled(CarouselStyled)`
    .button {
        display: none;
    }
    @media (min-width: ${breakpoints.sm}px) {
        .button {
            display: block;
            position: relative;
            bottom: 0;
            width: 17rem;

            button {
                font-size: ${({ theme }) => theme.fontSizes.textTiny};
            }
        }
    }

    .prev-button:hover,
    .prev-button:focus-visible {
        background-color: ${({ theme }) => theme.colors.primary};
    }

    .next-button:hover,
    .next-button:focus-visible {
        background-color: ${({ theme }) => theme.colors.primary};
    }

    #banner {
        &-top_level {
            background-size: 100% 100%;
            height: calc(100vw * 0.8);
            background-position-x: center;
            display: flex;
            align-items: flex-end;
            background-repeat: no-repeat;

            @media (min-width: ${breakpoints.xs}px) {
                height: calc(100vw * 0.3125);
            }

            @media (min-width: ${breakpoints.sm}px) {
                height: calc(100vw * 0.3112676056);
            }

            @media (min-width: ${breakpoints.md}px) {
                height: calc(100vw * 0.3118279569);
            }

            @media (min-width: ${breakpoints.lg}px) {
                height: calc(100vw * 0.277494537);
            }
        }
    }

    .image-placement {
        background-position-y: inherit !important;
        background-size: cover;
        background-position-x: inherit !important;
        width: -webkit-fill-available;
    }
`;
