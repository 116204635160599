import styled from 'styled-components';
import { css } from '../../../../styles/theme';
import { JourneySwitcherStyled } from '../../JourneySwitcherStyled';
import { rem } from 'polished';

export const JourneySwitcherStyledOVGB = styled(JourneySwitcherStyled)`
    ${({ isStock }) =>
        !isStock &&
        css`
            padding: unset;
        `}

    .back-container-not-active {
        background: none;
        margin-top: unset;
    }

    .option {
        padding: unset;
        background-color: ${({ theme }) => theme.colors.grey10};
        min-height: ${rem('68px')};
        border-top-right-radius: ${rem('12px')};
        border-top-left-radius: ${rem('12px')};
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .mobile-title {
        font-size: ${rem('16px')};
        font-weight: normal;
    }
    .web-title {
        font-size: ${rem('20px')};
        font-weight: bold;
    }

    .active {
        background-color: white;

        .mobile-title {
            font-weight: bold;
        }
    }

    .not-active {
        border-bottom: 2px solid ${({ theme }) => theme.colors.grey11};

        .mobile-title {
            color: ${({ theme }) => theme.colors.black};
        }

        .web-title {
            color: ${({ theme }) => theme.colors.grey1};
        }
    }
`;
