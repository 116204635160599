import { useEffect } from 'react';
import { MY_ACCOUNT_URL } from '../constants/main';
import useCustomRouter from './useCustomRouter';

export const useMyAccountRedirect = () => {
    const router = useCustomRouter();

    useEffect(() => {
        if (router.query && router.query.user_origin == 'setpassword' && router.query.status == 'success') {
            window.location.href = MY_ACCOUNT_URL;
        }
    }, [router]);
};

export default useMyAccountRedirect;
