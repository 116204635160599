import { rem } from 'polished';
import styled from 'styled-components';
import { OfferListItemStyled } from '../OfferListItemStyled';

export const OfferListItemStyledAP = styled(OfferListItemStyled)`
    display: grid;
    grid-template-rows: ${rem(60)} ${rem(200)} 1fr ${rem(64)} ${({ isStockJourney }) => (isStockJourney ? 0 : rem(80))};

    &.isNeedHandItem {
        display: flex;
    }

    .carTitleWrap {
        min-height: ${rem(60)};

        &--link {
            font-size: ${({ theme }) => theme.fontSizes.textBig};
            line-height: ${({ theme }) => theme.lineHeights.textBig};
        }
    }

    .descriptionWrap {
        color: ${({ theme }) => theme.colors.grey12};
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
        line-height: ${rem(14)};
    }
`;
